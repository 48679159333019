export const SUBHEADER = `Or, my stint as a freelance frontend contractor... [check it out!](https://romantic-almeida-4087f3.netlify.app/)`;

export const PREFACE = `I've seen a lot of snappy, concise retrospectives that modularize and bullet and make everything real digestible. This probably isn't going to be like those. What can I say, I’m a prose man... and it's also been a minute since I worked on this, so I may have forgotten exactly what was keeping me up at night in that time. But I’ll try get some of the more important stuff off first and then let it devolve into my *wish-I-was-a-blogger* reminiscing and reflection. The high level questions feel more interesting to write about anyway. Are they more interesting to read? I hope so.
`;

export const THE_DEETS = `## The Problem
Jeff wanted to update his personal portfolio website. He’s a brilliant designer, but like many software engineers, had no interest in frontend work. That's where I came in.  

## The Setup
Jeff sent me a figma file and the necessary image and font assets. **All** of the rest was up to me; there was no existing codebase or lingering opinions on architecture choices. Just like Al Haig in ’81, [I was in control here](https://youtu.be/zUKW0fL-OqY?t=35).  

In many ways, this appealed to me - it was an opportunity to take all of the opinions I’d formed on frontend best practices while at [Studio](/coming-soon) and build with those from the very beginning, no exceptions. In other ways it was daunting: there was a lot of setup to do, which unlike more regular contractors, I had no existing template or precedent to draw upon, and I wasn’t necessarily opinionated about *everything*. In fact, there were many opinions yet to be formed.

## Decisions
The opinions and goals I brought with me going in lead to the following stack:
* **Gatsby** - I love React and knew there would be some rich interactions that would make for juicy hooks. I also wanted all the performance of a simple static site, as a portfolio like this ought to be. Gatsby seemed like the intersection of those things, and though I'd never used it, this seemed like a good learning opportunity. Do all contractors try a new framework each project?
* **SCSS modules** for styling - a little more restraint with this decision, sticking with what was familiar and felt right. By this point I'd become aware of [tailwind](https://tailwindcss.com/) or [vanilla extract](https://vanilla-extract.style/) as the hot styling choices but I was familiar with SASS syntax and didn't want to learn *everything* from scratch. Was thrilled to use scoped modules, a nice practice that had not been universal in prior codebases.
* **Netlify** to deploy - simple, free for personal use; no regrets.
* **Netlify CMS** for content editing - the most ambitious call of all. Did I really need to build a CMS right away? Surely not. But I was learning Gatsby as I went and felt as though a CMS was the long term vision and that I should do it right from the start. Eh.
`;

export const REFLECTIONS = `## The Good, the Bad, and the Ugly
Ok, here's where the ~~prattling~~ **prose** begins. In many ways (“the Good”) the project was a success - a website was built, to completion, and now [lives on the world wide web](https://romantic-almeida-4087f3.netlify.app/). You’ll notice it’s not live anymore as Jeff’s portfolio; the man’s a fast mover and I don’t take it personally. We’ll classify that tidbit as “the Okay”.

Said website also came packaged with a CMS managing practically every bit of copy and every asset on the entire site. It is *sick*. Everything you could imagine is there, and all the pages have live previews so you can see exactly what it looks like as you edit your content. BUT... it also took a ton of time to figure out as I went; like half of development time was probably devoted to making sure the site ran in production and ran in the CMS with all the edge cases of possible data. Luckily (unluckily?), I wasn’t getting paid by the hour, and so as Jeff’s friend I think it was fine that some extra time was devoted to my learning new technologies and skills. Otherwise, as a contractor, this would be plain irresponsible. The CMS was a barely asked-for addition that came to dominate the project and got entirely in the way of fast-movement and time-to-ship. Classify the CMS as “the Beautiful but really actually super Ugly”.

Also in the mixed bag camp was my relationship with Gatsby during this project. For what it’s worth, it is super performant, and running lighthouse scores that no one else cared about and watching the numbers turn up green and close to 100 was *absolutely* a highlight of the entire experience (probably a remnant of my high school days as a GPA mercenary). We’re also clearly on good terms now, too, seeing as my personal site is also built in Gatsby. But frankly I had some frustrating moments trying to absorb everything there was to know about Gatsby’s sneaky abstractions and GraphQL and dynamic content and all the rest all at once. Life in the office for [mattkarle.com](/) was a lot easier having dropped all aspirations for elegant content structure. But I suppose I’m really glad I could build a CMS, if I really wanted. Knowledge is dope.

Finally, in a real diversion from technical detail, I feel compelled to touch upon the time management aspect involved in this project. It was hard, and there are surely takeaways to be had. At the time I was wrestling with GraphQL image errors nonstop, I was also buried under my most substantial undergraduate course load, employed already as course staff for CS50, and damn it, I was back at college for the first time in 18 months since Covid brought me unexpectedly into the workforce in 2020 - I wanted to be a college kid again. Between this overwhelming deluge of stimulants and commitments, and my aforementioned inexorable scope creep, the timeline suffered, and I felt bad about it. Now I know work can and perhaps should be hard, and if somehow a would-be employer is actually reading this, I maintain that I love a good challenge, but surely there’s some room for a little self-compassion in the mix too. More so than Gatsby or GraphQL or whatever other technical skill I picked up in this endeavor, I think finding that balance is perhaps the real lesson to be learned here.
`;
